<template>
  <div class="tokyo_tm_news">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Portfolio</span>
          <h3>Latest Portfolio</h3>
        </div>
      </div>
    </div>
    <ul class="tokyo_tm_news_inner">
      <li v-for="(portfolio,index) in myPortfolios" :key="portfolio.id">
        <div class="list_inner">
          <div class="image">
            <img src="../assets/img/thumbs/4-3.jpg" alt="thumb" />
            <div
              class="main"
              :style="{ backgroundImage: `url(${$store.state.baseURL}${portfolio.images[0].path})` }"
              @click="showModal(index)"
            ></div>
          </div>
          <!-- End .image -->
          <div class="details">
            <!-- End meta info -->
            <h3 class="title" @click="showModal(index)">
              {{portfolio.title}}
            </h3>
            <div class="tokyo_tm_read_more" @click="showModal(index)">
              <a><span>Read More</span></a>
            </div>
          </div>
          <!-- End .details -->
        </div>

        <!-- Start first modal -->
        <transition name="fade">
          <!-- Modal -->
          <div
            :class="{ 'modal-mask': isActive[index] }"
            id="modal"
            @click="closeModal(index)"
            v-if="isVisible[index]"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
              @click.stop
            >
              <div class="modal-content">
                <div class="tokyo_tm_modalbox_news">
                  <div class="close" @click="closeModal(index)">
                    <img
                      class="svg"
                      src="../assets/img/svg/cancel.svg"
                      alt="cancel-img"
                    />
                  </div>

                  <!-- END CLOSE ICON -->
                  <ul class="portfolio_list">
                    <CoolLightBox :items="allItems" :index="key" @close="key = null">
                    </CoolLightBox>
                    <li
                      v-for="(image, imageIndex) in allItems"
                      :key="imageIndex"
                      @click="key = imageIndex"
                    >
                      <div class="inner">
                        <div class="entry tokyo_tm_portfolio_animation_wrap">
                          <img class="image" :src="image.thumb" alt="Portfolio" style="height:200px" />
                        </div>
                      </div>
                    </li>
                    <!-- End li -->
                  </ul>

                  <!-- END IMAGE-->
                  <div class="details">
                    <a class="title" style="text-decoration: underline; cursor:pointer" target="_blank" :href="portfolio.link">
                        {{portfolio.title}}
                    </a>
                  </div>
                  <!-- END DETAILS -->
                  <div class="main_content ">
                    <div class="descriptions">
                      <p class="bigger" v-html="portfolio.description"></p>
                    </div>
                    <!-- END DESCRIPTION -->
                    <div class="news_share">
                      <span>Follow Me:</span>
                      <Social />
                      <!-- END SOCIAL SHARE -->
                    </div>
                    <!-- END NEWS SHARE -->
                  </div>
                </div>
                <!-- END MODALBOX NEWS -->
              </div>
            </div>
          </div>
        </transition>
        <!-- End second modal -->
      </li>
      <!-- End single blog -->
    </ul>
  </div>
</template>

<script>
import Social from "../components/Social";
import getPortfolio from "../composables/getPortfolio";
export default {
  components: {
    Social,
  },
  data() {
    return {
      myPortfolios:'',
      isActive: [],
      isVisible:[],
      allItems: [],
      key: null,
    };
  },
  methods: {
    showModal: function(index) {
      this.reset();
      this.myPortfolios[index].images.map((image)=>{
        let src;
        if(!image.src) src=this.$store.state.baseURL+image.path;
        else src=image.src;
        this.allItems.push({src:src,
        thumb:`${this.$store.state.baseURL}${image.path}`});
      })
      this.isActive[index] = true;
      this.isVisible[index] = true;
      
    },
    closeModal: function(index) {
      this.isActive[index] = false;
      this.isVisible[index] = false;
      this.reset();
    },

    reset(){
      this.isActive=[];
      this.isVisible=[];
      this.allItems=[];
    }
  },
  async mounted(){
    const {portfolios, load }=getPortfolio();
    await load();
    this.myPortfolios=portfolios;
  },
};
</script>

<style lang="scss" scoped></style>
