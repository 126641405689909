import axios from "axios";
import { ref } from "vue";

const getPortfolio = () => {
    const portfolios = ref(null);
    const load = async () => {
        await axios.get("/api/portfolios").then((res) => {
            portfolios.value = res.data.data;
        });
    };
    return { portfolios, load };
};

export default getPortfolio;
