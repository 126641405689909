<template>
  <ul class="social">
    <li v-for="(social, i) in socialList" :key="i">
      <a :href="social.link" target="_blank" rel="noreferrer"
        ><img class="svg" :src="social.src" alt="social"
      /></a>
    </li>
  </ul>
  <!-- End. social -->
</template>

<script>
  import getSocial from '../composables/getSocial';
  export default {
    data() {
      return {
        socialList: [],
      };
    },

    async mounted(){
      const  { socials, load }= getSocial();
      await load();
      socials.value.map((social)=>{
        this.socialList.push({
          link:social.link,
          src:require('../assets/img/svg/social/'+social.name+'.svg')
        });
      })
    }
  };
</script>

<style lang="scss" scoped></style>
