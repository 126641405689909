import axios from "axios";
import { ref } from "vue";

const getSocial = () => {
    const socials = ref(null);
    const load = async () => {
        await axios.get("/api/social-links").then((res) => {
            socials.value = res.data.data;
        });
    };
    return { socials, load };
};

export default getSocial;
